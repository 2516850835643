<template>
  <div>
    <content-section v-if="vehicle">
      <div class="my-5">
        <oto-typography text="Operational Flag" variant="one" />
        <div class="grid grid-cols-1 mt-5 gap-5 md:grid-cols-4">
          <VehicleFlagBox
            title="On Reserved"
            icon="on-reservation"
            :status="vehicle.status_flags.is_reserved"
          />
          <VehicleFlagBox
            title="On Ride"
            icon="on-ride"
            :status="vehicle.status_flags.is_on_ride"
          />
          <toggle-box
            v-model="vehicle.operational_flags.rebalance"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="getReadOnlyStatus"
            @click-box="showMessage"
            @change="onOff('O', 'rebalance', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="rebalance" />
                <div class="ml-3 title">
                  <div>Rebalance</div>
                  <div
                    v-if="
                      vehicle.task && vehicle.task.task_type === 'REBALANCING'
                    "
                    class="mt-1"
                  >
                    <router-link
                      :to="{
                        name: 'ViewTaskDetails',
                        params: { id: vehicle.task.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      Task #{{ $truncate(vehicle.task.id, -5) }}
                    </router-link>
                    <span class="text-gray-500"
                      >({{ vehicle.task.task_status }})</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </toggle-box>

          <toggle-box
            v-model="vehicle.operational_flags.charging_pick"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="getReadOnlyStatus"
            @click-box="showMessage"
            @change="onOff('O', 'charging_pick', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="charging-pick" />
                <div class="ml-4 title">
                  <div>Charging Pick</div>
                  <div
                    v-if="vehicle.task && vehicle.task.task_type === 'CHARGING'"
                    class="mt-1"
                  >
                    <router-link
                      :to="{
                        name: 'ViewTaskDetails',
                        params: { id: vehicle.task.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      Task #{{ $truncate(vehicle.task.id, -5) }}
                    </router-link>
                    <span class="text-gray-500"
                      >({{ vehicle.task.task_status }})</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </toggle-box>

          <toggle-box
            v-model="vehicle.operational_flags.maintenance"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="getReadOnlyStatus"
            @click-box="showMessage"
            @change="onOff('O', 'maintenance', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="maintenance" />
                <div class="ml-3 title">
                  <div>Maintenance</div>
                  <div
                    v-if="
                      vehicle.task && vehicle.task.task_type === 'MAINTENANCE'
                    "
                    class="mt-1"
                  >
                    <router-link
                      :to="{
                        name: 'ViewTaskDetails',
                        params: { id: vehicle.task.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      Task #{{ $truncate(vehicle.task.id, -5) }}
                    </router-link>
                    <span class="text-gray-500"
                      >({{ vehicle.task.task_status }})</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </toggle-box>
          <toggle-box
            v-model="vehicle.operational_flags.swap_battery"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="getReadOnlyStatus"
            @click-box="showMessage"
            @change="onOff('O', 'swap_battery', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="swap_battery" />
                <div class="ml-4 title">
                  <div>Swap Battery</div>
                  <div
                    v-if="
                      vehicle.task && vehicle.task.task_type === 'SWAP_BATTERY'
                    "
                    class="mt-1"
                  >
                    <router-link
                      :to="{
                        name: 'ViewTaskDetails',
                        params: { id: vehicle.task.id },
                      }"
                      :class="`text-blue-600`"
                      target="_blank"
                    >
                      Task #{{ $truncate(vehicle.task.id, -5) }}
                    </router-link>
                    <span class="text-gray-500"
                      >({{ vehicle.task.task_status }})</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </toggle-box>
        </div>
      </div>

      <div class="my-5">
        <oto-typography text="Vehicle Flag" variant="one" />
        <div class="grid grid-cols-1 mt-5 gap-5 md:grid-cols-4">
          <VehicleFlagBox
            title="Parking"
            icon="on-parking"
            :status="
              vehicle.status_flags.is_parking || vehicle.status_flags.is_idle
            "
          />
          <VehicleFlagBox
            title="On Charging"
            icon="on-charging"
            :status="vehicle.status_flags.is_charging"
          />
          <toggle-box
            v-model="vehicle.general_flags.missing"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="$acl.canNotUpdate('vehicles')"
            @change="onOff('G', 'missing', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="missing" />
                <div class="ml-4 title">Missing</div>
              </div>
            </template>
          </toggle-box>

          <toggle-box
            v-model="vehicle.general_flags.iot_fault"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="$acl.canNotUpdate('vehicles')"
            @change="onOff('G', 'iot_fault', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="iot-fault" />
                <div class="ml-4 title">IOT Fault</div>
              </div>
            </template>
          </toggle-box>

          <toggle-box
            v-model="vehicle.general_flags.geo_fence_alert"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="$acl.canNotUpdate('vehicles')"
            @change="onOff('G', 'geo_fence_alert', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="geofence-fault" />
                <div class="ml-4 title">Geofence Alert</div>
              </div>
            </template>
          </toggle-box>

          <toggle-box
            v-model="vehicle.general_flags.low_battery"
            :onValue="true"
            :offValue="false"
            size="base"
            :readonly="$acl.canNotUpdate('vehicles')"
            @change="onOff('G', 'low_battery', $event)"
          >
            <template v-slot:labels>
              <div class="flex items-center">
                <VehicleFlagIcon name="low-battery" />
                <div class="ml-4 title">Low Battery</div>
              </div>
            </template>
          </toggle-box>
        </div>
      </div>
    </content-section>
  </div>
</template>

<script>
import { VehicleConfig } from '@/config/VehicleConfig'

import ContentSection from '@/components/layout/ContentSection'
import OtoTypography from '@/components/ui/OtoTypography'
import ToggleBox from '@/components/form/ToggleBox.vue'
import VehicleFlagIcon from '@/components/badge/VehicleFlagIcon'
import VehicleFlagBox from '@/components/badge/VehicleFlagBox'

export default {
  name: 'ViewVehicleFlag',
  components: {
    ContentSection,
    OtoTypography,
    ToggleBox,
    VehicleFlagIcon,
    VehicleFlagBox,
  },
  data() {
    return {
      vehicle: null,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    getReadOnlyStatus() {
      if (
        this.$acl.canUpdate('vehicles') &&
        this.vehicle.task &&
        this.vehicle.task.task_status === 'PICKED'
      ) {
        return true
      } else {
        return false
      }
    },
  },
  async created() {
    console.log({ cre: this.vehicle })
    this.vehicle = this.$store.getters['vehicledetail/getData']

    if (!this.vehicle) {
      console.log({ fetching: this.id })
      await this.$store.dispatch('vehicledetail/fetchData', this.id)
      this.vehicle = this.$store.getters['vehicledetail/getData']
    }
    console.log({ crEE: this.vehicle })
  },
  methods: {
    showMessage() {
      if (this.vehicle?.task?.task_status === 'PICKED') {
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Error',
            text: 'You cannot change Flag while vehicle on task',
          },
          5000
        )
      }
    },

    async onOff(group, key, val) {
      let url = () => {
        if (group === 'general' || group === 'G') {
          return VehicleConfig.api.updateGeneralFLags(this.id)
        }
        if (group === 'operational' || group === 'O') {
          return VehicleConfig.api.updateOperationalFLags(this.id)
        }
        if (group === 'status' || group === 'S') {
          return VehicleConfig.api.updateStatusFLags(this.id)
        }
      }
      let updateModel = (modelKey, modelVal) => {
        let aliases = {
          G: 'general_flags',
          general: 'general_flags',
          O: 'operational_flags',
          operational: 'operational_flags',
          S: 'status_flags',
          status: 'status_flags',
        }
        let type = aliases[group] || null
        this.vehicle[type][modelKey] = modelVal
        return this.vehicle
        // console.log({ upMoType: type });
        // console.log({ upMo: this.vehicle[type][modelKey] });
        // console.log({ upMoDD: this.vehicle[type] });
      }
      console.log({ end: url() })
      let data = new FormData()
      data.append(key, val)
      // console.log("ad-", key);
      // console.log("adV-", val);
      await this.$http
        .patch(url(), data)
        .then(() => {
          //temporary solution--TODO
          this.$router.go()
          let updatedModel = updateModel(key, val)
          this.$store.dispatch('vehicledetail/saveData', updatedModel)

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Status updated',
              text: 'Status updated',
            },
            5000
          )
        })
        .catch((err) => {
          console.log('onOff-e', { err })
          // let groupKey
          // if (group === 'general' || group === 'G') {
          //   groupKey = 'general_flags'
          // }
          // if (group === 'operational' || group === 'O') {
          //   groupKey = 'operational_flags'
          // }
          // if (group === 'status' || group === 'S') {
          //   groupKey = 'status_flags'
          // }

          // this.vehicle[groupKey][key] = !val

          // this.$notify(
          //   {
          //     group: 'generic',
          //     type: 'error',
          //     title: 'Error',
          //     text: `${err.response.data.message}`,
          //   },
          //   5000
          // )
        })
    },
  },
}
</script>

<style lang="scss"></style>
