<template>
  <div class="wrapper" :class="getWrapperClass">
    <div class="flex items-center">
      <VehicleFlagIcon :name="icon" />
      <span class="ml-3 title">{{ title }}</span>
    </div>
    <div class="fleex">
      <div class="status" :class="getStatusClass"></div>
    </div>
  </div>
</template>

<script>
import VehicleFlagIcon from '@/components/badge/VehicleFlagIcon'

export default {
  name: 'VehicleFlagBox',
  props: ['title', 'status', 'icon'],
  components: { VehicleFlagIcon },
  computed: {
    getWrapperClass() {
      return this.status ? 'on' : 'off'
    },
    getStatusClass() {
      return this.status ? 'on' : 'off'
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  //   width: 225px;
  height: 60px;
  padding: 10px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  &.on {
    background-color: #f9fff8;
    border-color: #1bca09;
  }

  &.off {
    border-color: #e2e8f0;
    background-color: #f7f7f7;
  }

  & .title {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #343542;
  }

  .status {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    &.on {
      background-color: #1bca09;
    }
    &.off {
      background-color: #aeaeae;
    }
  }
}
</style>
